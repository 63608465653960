import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';

import Layout from '../../components/layout';
import SEO from '../../components/seo';
import Container from '../../components/common/container';
import Footer from '../../components/common/footer';
import ClubNews from '../../components/homepage/club-news';

const Hero = styled.div`
  background: linear-gradient(180deg, #c63d3d 0%, #d20707 100%);
  position: relative;
  padding: 16px;
  overflow: hidden;
  min-height: 200px;
  margin-bottom: -200px;

  @media screen and (min-width: 768px) {
    padding: 84px;
    min-height: 400px;
    margin-bottom: -240px;
  }

  svg {
    display: none;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 45%;
    height: 100%;
    fill: #eaeaea;

    @media screen and (min-width: 1200px) {
      display: block;
    }
  }
`;

const Wrap = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;

  @media screen and (min-width: 1200px) {
    max-width: 720px;
    text-align: left;
    align-items: flex-start;
  }
`;

const Title = styled.h1`
  color: white;
  font-size: 40px;
  font-weight: 800;
  line-height: 80px;
  margin-bottom: 16px;

  @media screen and (min-width: 768px) {
    font-size: 64px;
    margin-top: 140px;
  }
`;

const ArticleGrid = styled.div`
  display: flex;
  padding: ${props => props.theme.space[8]}px 0 0;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: ${props => props.theme.space[4]}px;
  }

  main {
    position: relative;
    z-index: 1000;
  }
`;

const NewsPage = ({ data }) => (
  <Layout isHomepage>
    <SEO title="Club News" />

    <Hero>
      <Wrap>
        <Title>Club News</Title>
      </Wrap>

      <svg viewBox="0 0 100 100" preserveAspectRatio="none">
        <defs>
          <linearGradient id="grad1" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop
              offset="0%"
              style={{ stopColor: 'rgb(45, 56, 97)', stopOpacity: 1 }}
            />
            <stop
              offset="100%"
              style={{ stopColor: 'rgb(55, 69, 132)', stopOpacity: 1 }}
            />
          </linearGradient>
        </defs>
        <polygon points="0, 100 100, -300 100, 100" fill="url(#grad1)" />
      </svg>
    </Hero>

    <Container>
      <ArticleGrid>
        <main>
          <ClubNews data={data.news.edges} noTitle noButton />
        </main>
      </ArticleGrid>
    </Container>

    <Footer />
  </Layout>
);

export const query = graphql`
  query {
    news: allSanityPost(sort: { fields: [publishedAt], order: DESC }) {
      edges {
        node {
          id
          title
          _rawLede
          slug {
            current
          }
          featureImage {
            asset {
              _id
            }
          }
        }
      }
    }
  }
`;

export default NewsPage;
