import styled from 'styled-components';
import { Link } from 'gatsby';

const Card = styled(Link)(
  props => `
  background-color: ${
    props.secondary ? props.theme.colors.red : props.theme.colors.navy
  };
  box-shadow: 0px 0px 20px rgba(34, 42, 79, 0.35);
  color: ${props.theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 366px;
  text-decoration: none;
  position: relative;

  h2 {
    font-size: 2rem;
    font-weight: ${props.theme.fontWeights.bold};
    position: relative;
    z-index: 2;
  }
`
);

export const ArticleCard = styled.div(
  props => `
  background-color: ${props.theme.colors.white};
  box-shadow: 0px 0px 25px rgba(34, 42, 79, 0.1);
  display: flex;
  flex-direction: column;
  position: relative;

  .gatsby-image-wrapper {
    height: 250px;
  }

  p {
    font-size: 1.4rem;
    line-height: 1.5;
  }
`
);

export default Card;
