import React from 'react';
import { Figure } from './components/portable-text';

export default {
  types: {
    block(props) {
      switch (props.node.style) {
        case 'inlineImage':
          return <Figure {...props.node} />;

        default:
          return <p>{props.children}</p>;
      }
    },
  },
};
