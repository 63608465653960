import React from 'react';
import styled from 'styled-components';
import BlockContent from '@sanity/block-content-to-react';
import { Link } from 'gatsby';

import Title from '../../common/title';
import Button from '../../common/button';
import serializers from '../../../serializers';
import { ArticleCard } from '../../common/card';
import Figure from '../../portable-text/Figure';

const CardGrid = styled.div(
  (props) => `
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 24px;
  margin-bottom: ${props.theme.space[4]}px;
  padding: 16px;

  @media screen and (min-width: 768px) {
    grid-template-columns: 1fr 1fr 1fr;
    margin-bottom: ${props.theme.space[6]}px;
  }
`
);

const CardTitle = styled.h2(
  (props) => `
  font-size: 1.5rem;
  line-height: 1.25;
  font-weight: ${props.theme.fontWeights.bold};
  margin-bottom: ${props.theme.space[3]}px;
  margin-top: ${props.theme.space[3]}px;

  @media screen and (min-width: 768px) {
    font-size: ${props.theme.fontSizes.headingFour};
  }
`
);

const CardBody = styled.div(
  (props) => `
    padding: ${props.theme.space[4]}px;

    p {
      font-size: 1.2rem;
      line-height: 1.5;
      margin-bottom: ${(props) => props.theme.space[4]}px;

      @media screen and (min-width: 768px) {
        font-size: 1.5rem;
      }
    }

    .card-link {
      display: inline-block;
      margin-top: 1rem;
      color: ${props.theme.colors.red};
      font-weight: 600;
      font-size: 1.25rem;
      text-decoration: none;

      &::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
        pointer-events: auto;
        content: "";
        background-color: rgba(0,0,0,0);
      }
    }
  `
);

const ButtonWrap = styled.div(
  (props) => `
  margin-bottom: ${props.theme.space[4]}px;
  text-align: center;
`
);

const ClubNews = ({ data, noButton, noTitle }) => {
  return (
    <div>
      {!noTitle && <Title>Club News</Title>}

      <CardGrid>
        {data.map((article) => (
          <ArticleCard key={article.node.id}>
            {console.log('article', article)}
            {article.node.featureImage && (
              <Figure asset={article.node.featureImage.asset} />
            )}

            <CardBody>
              <CardTitle>{article.node.title}</CardTitle>
              <BlockContent
                serializers={serializers}
                blocks={article.node._rawLede}
              />

              <Link
                to={`/news/${article.node.slug.current}`}
                className="card-link"
              >
                Read More
              </Link>
            </CardBody>
          </ArticleCard>
        ))}
      </CardGrid>

      {!noButton && (
        <ButtonWrap>
          <Button path="/news">View All News</Button>
        </ButtonWrap>
      )}
    </div>
  );
};

export default ClubNews;
