import styled from 'styled-components';

const Title = styled.h2`
  color: ${props => props.theme.colors.navy};
  font-weight: ${props => props.theme.fontWeights.thick};
  margin-bottom: ${props => props.theme.space[6]}px;
  margin-top: ${props => props.theme.space[6]}px;
  text-align: center;
  font-size: ${props => props.theme.fontSizes.headingThree};

  @media screen and (min-width: 768px) {
    font-size: ${props => props.theme.fontSizes.headingTwo};
    margin-bottom: ${props => props.theme.space[7]}px;
    margin-top: ${props => props.theme.space[8]}px;
  }
`;

export default Title;
